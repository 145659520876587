import moment from "moment";
import connect from "react-redux/es/connect/connect";
import {Box, Button, Link} from "@mui/material";

import {filtersChanged, setFilters} from "../../store/actions";
import DatePresetFilter from "./DatePresetFilter";
import DateFilter from "./DateFilter";
import MultiSelectFilter from "./MultiSelectFilter";

import _countries from "./countries.json";
import UserFilter from "./UserFilter";
import React, {useState} from "react";
import {Done, Visibility, VisibilityOff} from "@mui/icons-material";

const DATE_PRESETS = [
  { label: "Last Day", value: "last_day" },
  { label: "Last 3 Days", value: "last_3_days" },
  { label: "Last 7 Days", value: "last_7_days" },
  { label: "Last 14 Days", value: "last_14_days" },
  { label: "Last 30 Days", value: "last_30_days" },
  { label: "This Month", value: "this_month" },
  { label: "Last Month", value: "last_month" },
  { label: "This Year", value: "this_year" },
  { label: "Last Year", value: "last_year" },
];

const GENDERS = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
];

const COUNTRIES = _countries.map((c) => ({
  label: c.name,
  value: c["alpha-2"],
}));

const stateToProps = ({
  insights: {
    filters: { date_from, date_to, date_preset, countries },
  },
}) => {
  return {
    dateFrom: date_from,
    dateTo: date_to,
    datePreset: date_preset,
    countries,
  };
};

const actionToProps = (dispatch) => ({
  setFilters: (key, value) => dispatch(setFilters({ [key]: value })),
  filtersChanged: (data) => dispatch(filtersChanged({...data})),
});

function Filters({
  dateFrom,
  dateTo,
  datePreset,
  countries,
  setFilters,
  filtersChanged,
  dashboard,
  countriesHasData = false,
}) {
  const announceFiltersChange = () => {
    setTimeout(() => {
      filtersChanged({ dashboard: dashboard });
    }, 500);
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={{ display: "flex", gap: 3, mb: 4 }}>
        <DatePresetFilter
          presets={DATE_PRESETS}
          label="Date Range"
          onChange={(value) => {
            setFilters("date_preset", value);
            setFilters("date_to", undefined);
            setFilters("date_from", undefined);
          }}
          value={datePreset}
          customLabel="Custom Range"
          dateFrom={dateFrom}
          dateTo={dateTo}
          custom={() => {
            return (
              <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
                <DateFilter
                  label="Start Date"
                  value={dateFrom}
                  onChange={(newDateFrom) => {
                    setFilters("date_preset", undefined);
                    setFilters("date_from", newDateFrom);
                  }}
                  minDate={moment().subtract(3, "months").format("YYYY-MM-DD")}
                  maxDate={dateTo}
                />
                <DateFilter
                  label="End Date"
                  value={dateTo}
                  minDate={dateFrom}
                  maxDate={moment().format("YYYY-MM-DD")}
                  onChange={(newDateTo) => {
                    setFilters("date_preset", undefined);
                    setFilters("date_to", newDateTo);
                  }}
                />
              </Box>
            );
          }}
        />

        {countriesHasData && (
          <MultiSelectFilter
            sx={{ flex: "1 1 0px" }}
            label="Country"
            options={COUNTRIES}
            onChange={(t) => {
              setFilters("countries", t);
            }}
            value={countries}
          />
        )}

        <Button sx={{ whiteSpace: 'nowrap' }} variant="contained" endIcon={<Done />} onClick={() => {
          announceFiltersChange();
        }}>
          Apply filters
        </Button>
      </Box>
    </Box>
  );
}

export default connect(stateToProps, actionToProps)(Filters);
