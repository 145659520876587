const prepareFiltersForCSV = (filters) => {
  const preparedFilters = Object.keys(filters).map(key => [
    key,
    Array.isArray(filters[key]) ? filters[key].join(', ') : filters[key]
  ]);

  preparedFilters.unshift(['Filters']);
  preparedFilters.unshift(['']);
  preparedFilters.unshift(['']);
  preparedFilters.unshift(['']);

  return preparedFilters;
};

const convertJSONtoCSV = (jsonData, separator = ',') => {
  if (!Array.isArray(jsonData) || !jsonData.length || !Array.isArray(jsonData[0])) {
    console.error('Invalid JSON data');
    return null;
  }

  return jsonData
    .map(row => row.map(cell => `"${String(cell).replace(/"/g, '""')}"`).join(separator))
    .join('\n');
};

const downloadCSV = (jsonData, fileName) => {
  const csvContent = convertJSONtoCSV(jsonData);

  if (!csvContent) {
    return;
  }

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const downloadLink = document.createElement('a');

  downloadLink.href = URL.createObjectURL(blob);
  downloadLink.download = `${fileName}.csv`;

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export { convertJSONtoCSV, downloadCSV, prepareFiltersForCSV };