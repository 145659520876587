import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { CloudDownload } from "@mui/icons-material";
import {toHumanPercentage} from "@/utils/numbers";
import {convertJSONtoCSV, downloadCSV, prepareFiltersForCSV} from "@/utils/csvDownload";
import { visuallyHidden } from '@mui/utils';
import React from "react";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export default function TableVis({
  title,
  rows,
  headings,
  filters,
  size,
  hideHeadings,
  borders = true,
  tableContainerMaxWidth,
  tableContainerMaxHeight,
  hideDownload = false,
}) {
  const [order, setOrder] = React.useState();
  const [orderBy, setOrderBy] = React.useState();

  const determinePercentageColor = (value) => {
    if( value >= 0 ) {
      return 'success.main';
    }

    return 'danger.main';
  };

  const download = () => {
    const downloadableFilters = prepareFiltersForCSV(filters);

    const downloadableData = [
      headings.map(heading => heading.label),
      ...rows.map(row => headings.map(heading => row[heading.key])),
      ...downloadableFilters,
      ['time', (new Date()).toDateString()]
    ];

    const csvContent = convertJSONtoCSV(downloadableData);

    if (csvContent) {
      downloadCSV(downloadableData, title);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)),
    [order, orderBy]
  );

  return (
    <Paper sx={{ width: "100%", height: "100%", p: 4, position: 'relative', '&:hover .download-icon': { visibility: 'visible'} }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: tableContainerMaxWidth, height: tableContainerMaxHeight }}>
        {title && <Typography sx={{fontWeight: 'bold'}}>{title}</Typography>}

        <TableContainer component={Paper} sx={borders ? {} : { boxShadow: 0 }}>
          <Table aria-label={title} size={size} stickyHeader={true} sx={{ border: borders ? 'inherit' : 0 }}>
            {hideHeadings === false && (
              <TableHead>
                <TableRow>
                  {headings.map((heading, i) => (
                    <TableCell key={heading.key}>
                      <TableSortLabel
                        active={orderBy === heading.key}
                        direction={orderBy === heading.key ? order : 'asc'}
                        onClick={createSortHandler(heading.key)}
                      >
                        {heading.label}
                        {orderBy === heading.key ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {visibleRows.map((row, i) => (
                <TableRow
                  key={i}
                  sx={ borders ? { "&:last-child td, &:last-child th": { border: 0 } } : { "& td": { border: 0 } }}
                >
                  {headings.map((heading, i) => (
                    <TableCell key={`cell__i__${heading.key}`} align={heading.align}>
                      <Typography variant={'body2'} sx={{ color: heading.percentage ? determinePercentageColor(row[heading.key]) : 'text.primary' }}>
                        {heading.percentage ? toHumanPercentage(row[heading.key]) : row[heading.key] }
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {!hideDownload && (
        <Tooltip title="Download">
          <IconButton
            aria-label="download"
            className="download-icon"
            sx={{
              position: "absolute",
              top: "-16px",
              right: "-16px",
              visibility: "hidden",
            }}
            onClick={download}
          >
            <CloudDownload/>
          </IconButton>
        </Tooltip>
      )}
    </Paper>
  );
}


TableVis.propTypes = {
  title: PropTypes.string,
  hideHeadings: PropTypes.bool,
  headings: PropTypes.array,
  rows: PropTypes.array,
};

TableVis.defaultProps = {
  hideHeadings: false,
};
