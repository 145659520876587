const LOAD_INSIGHTS = "LOAD";
const SET_FILTERS = "SET_FILTERS";
const FILTERS_CHANGED = "FILTERS_CHANGED";
const SET_LOADING = "SET_LOADING";
const SET_DATA = "SET_DATA";

const SET_ACTIVE_USERS_COUNT = "SET_ACTIVE_USERS_COUNT";

const LOAD_AUDIENCES = "LOAD_AUDIENCES";
const SET_AUDIENCES = "SET_AUDIENCES";
const RESET_AUDIENCES = "RESET_AUDIENCES";
const LOAD_AUDIENCE_MEMBERS = "LOAD_AUDIENCE_MEMBERS";
const SET_AUDIENCE_MEMBERS = "SET_AUDIENCE_MEMBERS";
const LOAD_CREATE_AUDIENCE = "LOAD_CREATE_AUDIENCE";
const SET_CREATE_AUDIENCE = "SET_CREATE_AUDIENCE";
const LOAD_UPDATE_AUDIENCE = "LOAD_UPDATE_AUDIENCE";
const SET_UPDATE_AUDIENCE = "SET_UPDATE_AUDIENCE";
const LOAD_DELETE_AUDIENCE = "LOAD_DELETE_AUDIENCE";
const SET_DELETE_AUDIENCE = "SET_DELETE_AUDIENCE";
const LOAD_CHECK_AUDIENCES_INTERSECTION = "LOAD_CHECK_AUDIENCES_INTERSECTION";
const SET_CHECK_AUDIENCES_INTERSECTION = "SET_CHECK_AUDIENCES_INTERSECTION";
const RESET_CHECK_AUDIENCES_INTERSECTION = "RESET_CHECK_AUDIENCES_INTERSECTION";
const LOAD_INTERSECT_AUDIENCES = "LOAD_INTERSECT_AUDIENCES";
const SET_INTERSECT_AUDIENCES = "SET_INTERSECT_AUDIENCES";

const LOAD_CUSTOM_INSIGHTS = "LOAD_CUSTOM_INSIGHTS";
const SET_CUSTOM_INSIGHTS = "SET_CUSTOM_INSIGHTS";
const LOAD_CREATE_CUSTOM_INSIGHT = "LOAD_CREATE_CUSTOM_INSIGHT";
const SET_CREATE_CUSTOM_INSIGHT = "SET_CREATE_CUSTOM_INSIGHT";
const LOAD_ARCHIVE_CUSTOM_INSIGHT = "LOAD_ARCHIVE_CUSTOM_INSIGHT";
const LOAD_UPDATE_CUSTOM_INSIGHT = "LOAD_UPDATE_CUSTOM_INSIGHT";
const SET_UPDATE_CUSTOM_INSIGHT = "SET_UPDATE_CUSTOM_INSIGHT";
const SET_ARCHIVE_CUSTOM_INSIGHT = "SET_ARCHIVE_CUSTOM_INSIGHT";
const LOAD_UNARCHIVE_CUSTOM_INSIGHT = "LOAD_UNARCHIVE_CUSTOM_INSIGHT";
const SET_UNARCHIVE_CUSTOM_INSIGHT = "SET_UNARCHIVE_CUSTOM_INSIGHT";
const LOAD_HIDE_CUSTOM_INSIGHT = "LOAD_HIDE_CUSTOM_INSIGHT";
const SET_HIDE_CUSTOM_INSIGHT = "SET_HIDE_CUSTOM_INSIGHT";
const LOAD_SHOW_CUSTOM_INSIGHT = "LOAD_UNHIDE_CUSTOM_INSIGHT";
const SET_SHOW_CUSTOM_INSIGHT = "SET_UNHIDE_CUSTOM_INSIGHT";

const LOAD_CUSTOM_INSIGHTS_TEMPLATES = "LOAD_CUSTOM_INSIGHTS_TEMPLATES";
const SET_CUSTOM_INSIGHTS_TEMPLATES = "SET_CUSTOM_INSIGHTS_TEMPLATES";

const SET_SELECTED_LEFT_AXIS_ITEMS = "SET_SELECTED_LEFT_AXIS_ITEMS";
const SET_LEFT_AXIS_RENDITION_TYPE = "SET_LEFT_AXIS_RENDITION_TYPE";
const SET_SELECTED_RIGHT_AXIS_ITEMS = "SET_SELECTED_RIGHT_AXIS_ITEMS";
const SET_RIGHT_AXIS_RENDITION_TYPE = "SET_RIGHT_AXIS_RENDITION_TYPE";
const SET_AXIS_USE_COMMON_Y_SCALE = "SET_AXIS_USE_COMMON_Y_SCALE";
const SET_SELECTED_BREAKDOWN_ITEM = "SET_SELECTED_BREAKDOWN_ITEM";

export {
  LOAD_INSIGHTS,
  SET_FILTERS,
  FILTERS_CHANGED,
  SET_LOADING,
  SET_DATA,
  SET_ACTIVE_USERS_COUNT,
  LOAD_AUDIENCES,
  SET_AUDIENCES,
  RESET_AUDIENCES,
  LOAD_AUDIENCE_MEMBERS,
  SET_AUDIENCE_MEMBERS,
  LOAD_CREATE_AUDIENCE,
  SET_CREATE_AUDIENCE,
  LOAD_UPDATE_AUDIENCE,
  SET_UPDATE_AUDIENCE,
  LOAD_DELETE_AUDIENCE,
  SET_DELETE_AUDIENCE,
  LOAD_CHECK_AUDIENCES_INTERSECTION,
  SET_CHECK_AUDIENCES_INTERSECTION,
  RESET_CHECK_AUDIENCES_INTERSECTION,
  LOAD_INTERSECT_AUDIENCES,
  SET_INTERSECT_AUDIENCES,
  LOAD_CUSTOM_INSIGHTS,
  SET_CUSTOM_INSIGHTS,
  LOAD_CREATE_CUSTOM_INSIGHT,
  SET_CREATE_CUSTOM_INSIGHT,
  LOAD_UPDATE_CUSTOM_INSIGHT,
  SET_UPDATE_CUSTOM_INSIGHT,
  LOAD_ARCHIVE_CUSTOM_INSIGHT,
  SET_ARCHIVE_CUSTOM_INSIGHT,
  LOAD_UNARCHIVE_CUSTOM_INSIGHT,
  SET_UNARCHIVE_CUSTOM_INSIGHT,
  LOAD_HIDE_CUSTOM_INSIGHT,
  SET_HIDE_CUSTOM_INSIGHT,
  LOAD_SHOW_CUSTOM_INSIGHT,
  SET_SHOW_CUSTOM_INSIGHT,
  LOAD_CUSTOM_INSIGHTS_TEMPLATES,
  SET_CUSTOM_INSIGHTS_TEMPLATES,
  SET_SELECTED_LEFT_AXIS_ITEMS,
  SET_SELECTED_RIGHT_AXIS_ITEMS,
  SET_LEFT_AXIS_RENDITION_TYPE,
  SET_RIGHT_AXIS_RENDITION_TYPE,
  SET_AXIS_USE_COMMON_Y_SCALE,
  SET_SELECTED_BREAKDOWN_ITEM,
};