import React, {useEffect, useState} from 'react';
import connect from 'react-redux/es/connect/connect';

import {Link as RouterLink} from 'react-router-dom';

import {
    Box,
    Button,
    Card,
    CardContent,
    Typography,
} from '@mui/material';
import Logo from '../../../components/vectors/Logo';
import {useSnackbar} from 'notistack';

import {apiGet} from 'AppUtils/api';
import Observable from 'Observable';
import LoadingScreen from 'AppComponents/LoadingScreen';
import {getQueryVariable} from "../../../utils/url";
import {uiRedirect, uiReplace} from '../../../store/actions';
import GuestPage from "../../../components/GuestPage";

const stateToProps = state => ({});

const actionsToProps = dispatch => ({
    uiRedirect: (payload) => dispatch(uiRedirect(payload)),
    uiReplace: (payload) => dispatch(uiReplace(payload)),
});

const Invitation = (props) => {
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const token = getQueryVariable(window.location.search, 'token');
    const [errorCode, setErrorCode] = useState(null);
    const [resetPassword, setResetPassword] = useState(false);
    const [resetToken, setResetToken] = useState(null);

    if (props.authIsAuthenticated) {
        props.uiRedirect({
            pathname: '/'
        });
    }

    const onSend = () => {
        setIsLoading(true);
        let statusCode = '';

        apiGet('/user-invite', {token})
            .catch((e) => {
                return Observable.empty();
            }).mergeMap(res => {
            const resp = res.json();
            statusCode = res.status;
            return resp;
        }).toPromise().then(function (response) {
            if (response && statusCode === 200) {
                if (response.reset_password_needed) {
                    setResetPassword(true);
                    setResetToken(response.reset_token);
                }
            } else {
                enqueueSnackbar(response.message, {
                    variant: 'error',
                    persist: true
                });

                let errorMsg = response.message;
                let errorMessageMatch;

                if(response.errors) {
                    errorMsg = Object.values(response.errors)
                        .map(i => i[0] || null)
                        .filter(Boolean)
                        .first() || errorMsg;
                    errorMessageMatch = errorMsg.match(/\[#([^\]]+)]/);

                    if(errorMessageMatch) {
                        errorMsg = errorMsg
                            .replace(`${errorMessageMatch[0]}`, '')
                            .replaceAll(/(\s)+/g, '$1')
                            .replaceAll(/\s([,;.])/g, '$1')
                            .trim();
                        setErrorCode(errorMessageMatch[1]);
                    }
                }

                setError(errorMsg);
            }

            setIsLoading(false);
        });
    };

    const setPassword = (resetToken) => {
        props.uiRedirect({
            pathname: `/recover`,
            search: `?token=${resetToken}`,
            state: {choosePassword: 1}
        });
    }

    useEffect(() => {
        onSend();
    }, []);

    if (props.appGetStatus === 'loading' || isLoading) {
        return (<LoadingScreen/>);
    }

    const renderErrorInstruction = (error, errorCode) => {
        let errorTemplate;

        switch(errorCode) {
            case 'UI001':
                errorTemplate = (
                    <>
                        <Typography variant="body1" color="textPrimary">
                            If you copy-pasted the invitation link from your email please make sure you selected all of it and try again.
                        </Typography>

                        <Box mt={2}>
                            <Typography variant="body1" color="textPrimary">
                                If you clicked the button or the link in your email, probably the invitation is no longer valid.
                            </Typography>

                            <Typography variant="body1" color="textPrimary">
                                In this case, please request your platform manager to issue another invitation and follow the invitation link within 7 days.
                            </Typography>
                        </Box>
                    </>
                );
                break;
            case 'UI002':
                errorTemplate = (
                    <>
                        <Typography variant="body1" color="textPrimary">
                            Please request your platform manager to issue another invitation, check your email and and follow the instructions in the received email.
                        </Typography>
                    </>
                );
                break;
            case 'UI004':
                errorTemplate = (
                    <>
                        <Typography variant="body1" color="textPrimary">
                            Please request your platform manager to issue a password request in your name, check your email and and follow the instructions in the received email.
                        </Typography>
                    </>
                );
                break;
        }

        return errorTemplate;
    }

    const renderSuccess = (resetPassword, resetToken) => {
        let successTemplate;

        if(resetPassword) {
            successTemplate = (
                <>
                    <Box mt={2}>
                        <Typography variant="body1" color="textPrimary">
                            Proceed to set your account's password.
                        </Typography>
                    </Box>

                    <Box mt={2} textAlign="center">
                        <Button variant="contained" color="primary" onClick={() => setPassword(resetToken)}>
                            Set password
                        </Button>
                    </Box>
                </>
            );
        } else {
            successTemplate = (
                <>
                    <Box mt={2}>
                        <Typography variant="body1" color="textPrimary">
                            You can now login to you account to access Client data
                        </Typography>
                    </Box>

                    <Box mt={5} textAlign="center">
                        <Button component={RouterLink} to={`/login`} variant="contained" color="secondary">
                            Login
                        </Button>
                    </Box>
                </>
            );
        }

        return successTemplate;
    }

    return (
        <GuestPage title="Member Invitation">
            <Box sx={{ width: 1, height: 1, minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ maxWidth:'sm' }}>
                    <Box mb={8} display="flex" justifyContent="center">
                        <RouterLink to="/">
                            <Logo  width={150}/>
                        </RouterLink>
                    </Box>

                    <Card variant="outlined">
                        <CardContent>
                            <Box mb={3}>
                                <Typography variant="h2" sx={{ fontWeight: 'regular', color: 'text.primary' }}>
                                    Member invitation
                                </Typography>

                                <Typography variant="body2" color="textSecondary">
                                    Thank you for accessing the Isara AI platform.
                                </Typography>
                            </Box>

                            <Box mt={4}>
                                {error ?
                                    <>
                                        <Typography variant="body1" color="textPrimary">
                                            Sadly the onboarding request could not be completed.
                                        </Typography>

                                        <Box mt={2}>
                                            <Typography variant="body1" color="textPrimary">
                                                Cause: <span>{error}</span>
                                            </Typography>
                                            {errorCode && (
                                                <Typography variant="body1" color="textPrimary">
                                                    Code: #{errorCode}
                                                </Typography>
                                            )}
                                        </Box>
                                        <Box mt={2}>
                                            {renderErrorInstruction(error, errorCode)}
                                        </Box>
                                        <Box mt={5}>
                                            <Typography variant="body1" color="textPrimary">
                                                Click <RouterLink to="/login">here</RouterLink> to go to the Login screen
                                            </Typography>
                                        </Box>
                                    </>
                                    : (
                                        <>
                                            {renderSuccess(resetPassword, resetToken)}
                                        </>
                                    )
                                }
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            </Box>
        </GuestPage>
    );
};

export default connect(stateToProps, actionsToProps)(Invitation);
