import {all, fork, put, select, takeEvery, race, join} from "redux-saga/effects";

import { apiGet } from "@/utils/api";
import {
  SET_LOADING,
  SET_DATA,
  SET_AUDIENCES,
  SET_AUDIENCE_MEMBERS,
  SET_CUSTOM_INSIGHTS,
  SET_CUSTOM_INSIGHTS_TEMPLATES, LOAD_AUDIENCES, SET_ACTIVE_USERS_COUNT
} from "./types";
import {getFilters, getInsightsSections, getTenantId} from "./selectors";
import {Observable} from "rxjs";
import * as INSIGHTS from "./types";
import {apiDelete, apiPatch, apiPostJson} from "../../../utils/api";

function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

function get(url, data, options) {
  return apiGet(url, data, options)
    .mergeMap((res) => res.json())
    .toPromise()
    .then(function (response) {
      if (response && !response.errors) {
        return response;
      } else {
        return Promise.reject(response);
      }
    });
}

function clearEmpty(filters) {
  return Object.fromEntries(
    Object.entries(filters).filter(([k, v]) => {
      if (
        v === null ||
        v === undefined ||
        (Array.isArray(v) && v.length === 0)
      ) {
        return false;
      }

      return true;
    })
  );
}

function* fetchForType(type, url) {
  const filters = yield select(getFilters);
  const tenantId = yield select(getTenantId);

  if (tenantId === undefined) {
    return;
  }

  const apiUrl = `/${tenantId}/insights/${url}`;

  // Set isLoading to true for the given section immediately
  yield put({ type: SET_LOADING, payload: { section: type, isLoading: true, isLoadingForALongTime: false } });

  if( type === 'user' ) {
    // Set isLoading for all the sections
    yield setInsightsDashboardLoading(true);
  }

  try {
    const task = yield fork(get, apiUrl, clearEmpty(filters));

    let { result } = yield race({
      result: join(task),
      timeout: delay(10000),
    });

    if (result) {
      // Request completed within 10 seconds, set isLoadingForALongTime to false
      yield put({ type: SET_LOADING, payload: { section: type, isLoading: true, isLoadingForALongTime: false } });
    } else {
      yield put({ type: SET_LOADING, payload: { section: type, isLoading: true, isLoadingForALongTime: true } });
      result = yield join(task);
    }

    // Data fetching successful, update the state
    yield put({ type: SET_DATA, payload: { section: type, data: result.data } });

    if( type === 'user' ) {
      const activeUsersCount = result.data.active.value;

      if( activeUsersCount >= 2 ) {
        yield fork(fetchInsightsDashboardData);
      } else {
        yield setInsightsDashboardLoading(false);
      }

      yield put({ type: SET_ACTIVE_USERS_COUNT, payload: { activeUsersCount: activeUsersCount } });
    }
  } catch (e) {
    console.error(e);
    // TODO handle errors in UI too
  } finally {
    // Set isLoading to false as soon as the request is done
    yield put({ type: SET_LOADING, payload: { section: type, isLoading: false } });
  }
}

function* fetchUser() {
  yield fetchForType("user", "dashboard/users");
}

function* fetchKeyboardEngagement() {
  yield fetchForType("keyboardEngagement", "dashboard/keyboard-engagement");
}

function* fetchTypedTerms() {
  yield fetchForType("typedTerms", "dashboard/typed-terms");
}

function* fetchTrendingTypedTerms() {
  yield fetchForType("trendingTypedTerms", "dashboard/trending-typed-terms");
}

function* fetchGenders() {
  yield fetchForType("genders", "dashboard/genders");
}

function* fetchAgeGroups() {
  yield fetchForType("ageGroups", "dashboard/age-groups");
}

function* fetchInterests() {
  yield fetchForType("interests", "dashboard/interests");
}

function* fetchLanguages() {
  yield fetchForType("languages", "dashboard/languages");
}

function* fetchCountries() {
  yield fetchForType("countries", "dashboard/countries");
}

function* fetchCustomInsightsResults() {
  yield fetchForType("customInsightsResults", "custom-insights/results");
}

function* fetchProblemOrFeatureMentions() {
  yield fetchForType("problemOrFeatureMentions", "dashboard/problem-or-feature-mentions");
}

function* onFiltersChanged(action) {
  const { dashboard } = action.payload;

  if( dashboard === 'old-dashboard' ) {
    yield fork(fetchUser);
  }
}

function* fetchInsightsDashboardData() {
  yield all([
    fork(fetchKeyboardEngagement),
    fork(fetchTypedTerms),
    fork(fetchTrendingTypedTerms),
    fork(fetchGenders),
    fork(fetchAgeGroups),
    fork(fetchInterests),
    fork(fetchLanguages),
    fork(fetchCountries),
    fork(fetchCustomInsightsResults),
    fork(fetchProblemOrFeatureMentions),
  ]);
}

function* setInsightsDashboardLoading(loading)  {
  const sections = yield(select(getInsightsSections));
  let puts = [];

  for( let sectionName in sections ) {
    puts.push(put({ type: SET_LOADING, payload: { section: sectionName, isLoading: loading, isLoadingForALongTime: false } }));
  }

  yield all(puts);
}

function* onLoadAudiences(action) {
  const retry = 3;
  let statusCode = 0;
  let data = [];
  let message = "";

  yield apiGet(`/${action.payload.tenantId}/insights/audiences`)
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .catch((e) => Observable.of([]))
    .mergeMap((res) => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    })
    .toPromise()
    .then((response) => {
      if (response && !response.errors) {
        data = response.data;
      } else {
        message = response.message;
      }
    });

  yield put({
    type: SET_AUDIENCES,
    payload: {data, message, statusCode}
  });
}

function* onLoadAudienceMembers(action) {
  const retry = 3;
  let statusCode = 0;
  let data = [];
  let message = "";

  yield apiGet(`/${action.payload.tenantId}/insights/audiences/${action.payload.audienceUid}/members`)
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .catch((e) => Observable.of([]))
    .mergeMap((res) => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    })
    .toPromise()
    .then((response) => {
      if (response && !response.errors) {
        data = response.data;
      } else {
        message = response.message;
      }
    });

  yield put({
    type: SET_AUDIENCE_MEMBERS,
    payload: {
      audienceUid: action.payload.audienceUid,
      data,
      message,
      statusCode,
    },
  });
}

function* onLoadCreateAudience(action) {
  const retry = 3;
  let statusCode = 0;
  let errors = [];
  let message = "";

  yield apiPostJson(`/${action.payload.tenantId}/insights/audiences`, {
    audience_name: action.payload.audienceName,
    third_party_ids_type: action.payload.thirdPartyIdType,
    third_party_ids: action.payload.userIds,
  })
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .catch((e) => Observable.of([]))
    .mergeMap((res) => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    })
    .toPromise()
    .then(response => {
      if ( !response || response.errors ) {
        message = response.message;
        errors = response.errors;
      }
    });

  yield put({
    type: INSIGHTS.SET_CREATE_AUDIENCE,
    payload: {errors, message, statusCode},
  });
}

function* onLoadUpdateAudience(action)  {
  const retry = 3;
  let statusCode = 0;
  let errors = [];
  let data = {};
  let message = "";

  yield apiPatch(`/${action.payload.tenantId}/insights/audiences/${action.payload.audienceUid}`, {
    audience_name: action.payload.audienceName,
  })
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .catch((e) => Observable.of([]))
    .mergeMap((res) => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    })
    .toPromise()
    .then(response => {
      if ( !response || response.errors ) {
        message = response.message;
        errors = response.errors;
      } else {
        data = response.data;
      }
    });

  yield put({
    type: INSIGHTS.SET_UPDATE_AUDIENCE,
    payload: {
      audienceUid: action.payload.audienceUid,
      data,errors, message, statusCode},
  });
}

function* onLoadCheckAudiencesIntersection(action) {
  const retry = 3;
  let statusCode = 0;
  let errors = [];
  let message = "";
  let data = {};

  yield apiPostJson(`/${action.payload.tenantId}/insights/audiences/intersection/check`, {
    audience_uids: action.payload.audienceUids,
  })
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .catch((e) => Observable.of([]))
    .mergeMap((res) => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    })
    .toPromise()
    .then(response => {
      if ( !response || response.errors ) {
        message = response.message;
        errors = response.errors;
      } else {
        data = response;
      }
    });

  yield put({
    type: INSIGHTS.SET_CHECK_AUDIENCES_INTERSECTION,
    payload: {data, errors, message, statusCode},
  });
}

function* onLoadIntersectAudiences(action) {
  const retry = 3;
  let statusCode = 0;
  let errors = [];
  let message = "";
  let data = {};

  yield apiPostJson(`/${action.payload.tenantId}/insights/audiences/intersection`, {
    audience_name: action.payload.audienceName,
    audience_uids: action.payload.audienceUids,
  })
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .catch((e) => Observable.of([]))
    .mergeMap((res) => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    })
    .toPromise()
    .then(response => {
      if ( !response || response.errors ) {
        message = response.message;
        errors = response.errors;
      }
    });

  yield put({
    type: INSIGHTS.SET_INTERSECT_AUDIENCES,
    payload: {data, errors, message, statusCode},
  });
}

function* onLoadDeleteAudience(action) {
  const retry = 3;
  let statusCode = 0;
  let message = "";

  yield apiDelete(`/${action.payload.tenantId}/insights/audiences/${action.payload.audienceUid}`)
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .catch((e) => Observable.of([]))
    .mergeMap((res) => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    })
    .toPromise()
    .then((response) => {
      message = response.message;
    });

  yield put({
    type: INSIGHTS.SET_DELETE_AUDIENCE,
    payload: {message, statusCode}
  });

  yield put({
    type: INSIGHTS.LOAD_AUDIENCES,
    payload: {
      tenantId: action.payload.tenantId,
    },
  });
}

function* onLoadCustomInsights(action) {
  const retry = 3;
  let statusCode = 0;
  let data = [];
  let message = "";

  yield apiGet(`/${action.payload.tenantId}/insights/custom-insights`)
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .catch((e) => Observable.of([]))
    .mergeMap((res) => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    })
    .toPromise()
    .then((response) => {
      if (response && !response.errors) {
        data = response.data;
      } else {
        message = response.message;
      }
    });

  yield put({
    type: SET_CUSTOM_INSIGHTS,
    payload: {data, message, statusCode}
  });
}

function* onLoadCreateCustomInsight(action) {
  const retry = 3;
  let statusCode = 0;
  let errors = [];
  let message = "";

  yield apiPostJson(`/${action.payload.tenantId}/insights/custom-insights`, {
    ...action.payload.payloadData
  })
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .catch((e) => Observable.of([]))
    .mergeMap((res) => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    })
    .toPromise()
    .then(response => {
      if ( !response || response.errors ) {
        message = response.message;
        errors = response.errors;
      }
    });

  yield put({
    type: INSIGHTS.SET_CREATE_CUSTOM_INSIGHT,
    payload: {errors, message, statusCode},
  });

  if( statusCode === 201 ) {
    yield put({
      type: INSIGHTS.LOAD_CUSTOM_INSIGHTS,
      payload: {
        tenantId: action.payload.tenantId,
      },
    });
  }
}

function* onLoadUpdateCustomInsight(action) {
  const retry = 3;
  let statusCode = 0;
  let errors = [];
  let data = {};
  let message = "";

  yield apiPatch(`/${action.payload.tenantId}/insights/custom-insights/${action.payload.customInsightUid}`, {
    name: action.payload.customInsightName,
  })
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .catch((e) => Observable.of([]))
    .mergeMap((res) => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    })
    .toPromise()
    .then(response => {
      if ( !response || response.errors ) {
        message = response.message;
        errors = response.errors;
      } else {
        data = response.data;
      }
    });

  yield put({
    type: INSIGHTS.SET_UPDATE_CUSTOM_INSIGHT,
    payload: {
      customInsightUid: action.payload.customInsightUid,
      data, errors, message, statusCode
    },
  });
}

function* onLoadArchiveCustomInsight(action) {
  const retry = 3;
  let statusCode = 0;
  let errors = [];
  let message = "";

  yield apiDelete(`/${action.payload.tenantId}/insights/custom-insights/${action.payload.customInsightUid}`)
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .catch((e) => Observable.of([]))
    .mergeMap((res) => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    })
    .toPromise()
    .then(response => {
      message = response.message;

      if ( !response || response.errors ) {
        errors = response.errors;
      }
    });

  yield put({
    type: INSIGHTS.SET_ARCHIVE_CUSTOM_INSIGHT,
    payload: {errors, message, statusCode},
  });

  yield put({
    type: INSIGHTS.LOAD_CUSTOM_INSIGHTS,
    payload: {
      tenantId: action.payload.tenantId,
    },
  });
}

function* onLoadUnarchiveCustomInsight(action) {
  const retry = 3;
  let statusCode = 0;
  let errors = [];
  let message = "";

  yield apiPostJson(`/${action.payload.tenantId}/insights/custom-insights/${action.payload.customInsightUid}/unarchive`, {})
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .catch((e) => Observable.of([]))
    .mergeMap((res) => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    })
    .toPromise()
    .then(response => {
      message = response.message;

      if ( !response || response.errors ) {
        errors = response.errors;
      }
    });

  yield put({
    type: INSIGHTS.SET_UNARCHIVE_CUSTOM_INSIGHT,
    payload: {errors, message, statusCode},
  });

  yield put({
    type: INSIGHTS.LOAD_CUSTOM_INSIGHTS,
    payload: {
      tenantId: action.payload.tenantId,
    },
  });
}

function* onLoadHideCustomInsight(action) {
  const retry = 3;
  let statusCode = 0;
  let errors = [];
  let message = "";

  yield apiPostJson(`/${action.payload.tenantId}/insights/custom-insights/${action.payload.customInsightUid}/hide`, {})
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .catch((e) => Observable.of([]))
    .mergeMap((res) => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    })
    .toPromise()
    .then(response => {
      message = response.message;

      if ( !response || response.errors ) {
        errors = response.errors;
      }
    });

  yield put({
    type: INSIGHTS.SET_HIDE_CUSTOM_INSIGHT,
    payload: {errors, message, statusCode},
  });

  yield put({
    type: INSIGHTS.LOAD_CUSTOM_INSIGHTS,
    payload: {
      tenantId: action.payload.tenantId,
    },
  });
}

function* onLoadShowCustomInsight(action) {
  const retry = 3;
  let statusCode = 0;
  let errors = [];
  let message = "";

  yield apiPostJson(`/${action.payload.tenantId}/insights/custom-insights/${action.payload.customInsightUid}/unhide`, {})
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .catch((e) => Observable.of([]))
    .mergeMap((res) => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    })
    .toPromise()
    .then(response => {
      message = response.message;

      if ( !response || response.errors ) {
        errors = response.errors;
      }
    });

  yield put({
    type: INSIGHTS.SET_SHOW_CUSTOM_INSIGHT,
    payload: {errors, message, statusCode},
  });

  yield put({
    type: INSIGHTS.LOAD_CUSTOM_INSIGHTS,
    payload: {
      tenantId: action.payload.tenantId,
    },
  });
}

function* onLoadCustomInsightsTemplates(action) {
  const retry = 3;
  let statusCode = 0;
  let data = [];
  let message = "";

  yield apiGet(`/insights/custom-insights-templates`)
    .retryWhen((errors) => errors.delay(1000).take(retry))
    .catch((e) => Observable.of([]))
    .mergeMap((res) => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    })
    .toPromise()
    .then((response) => {
      if (response && !response.errors) {
        data = response.data;
      } else {
        message = response.message;
      }
    });

  yield put({
    type: SET_CUSTOM_INSIGHTS_TEMPLATES,
    payload: {data, message, statusCode}
  });
}

function* watchInitialize() {
  yield takeEvery(INSIGHTS.FILTERS_CHANGED, onFiltersChanged);
  yield takeEvery(INSIGHTS.LOAD_INSIGHTS, onFiltersChanged);
  yield takeEvery(INSIGHTS.LOAD_AUDIENCES, onLoadAudiences);
  yield takeEvery(INSIGHTS.LOAD_AUDIENCE_MEMBERS, onLoadAudienceMembers);
  yield takeEvery(INSIGHTS.LOAD_CREATE_AUDIENCE, onLoadCreateAudience);
  yield takeEvery(INSIGHTS.LOAD_UPDATE_AUDIENCE, onLoadUpdateAudience);
  yield takeEvery(INSIGHTS.LOAD_CHECK_AUDIENCES_INTERSECTION, onLoadCheckAudiencesIntersection);
  yield takeEvery(INSIGHTS.LOAD_INTERSECT_AUDIENCES, onLoadIntersectAudiences);
  yield takeEvery(INSIGHTS.LOAD_DELETE_AUDIENCE, onLoadDeleteAudience);
  yield takeEvery(INSIGHTS.LOAD_CUSTOM_INSIGHTS, onLoadCustomInsights);
  yield takeEvery(INSIGHTS.LOAD_CREATE_CUSTOM_INSIGHT, onLoadCreateCustomInsight);
  yield takeEvery(INSIGHTS.LOAD_UPDATE_CUSTOM_INSIGHT, onLoadUpdateCustomInsight);
  yield takeEvery(INSIGHTS.LOAD_ARCHIVE_CUSTOM_INSIGHT, onLoadArchiveCustomInsight);
  yield takeEvery(INSIGHTS.LOAD_UNARCHIVE_CUSTOM_INSIGHT, onLoadUnarchiveCustomInsight);
  yield takeEvery(INSIGHTS.LOAD_CUSTOM_INSIGHTS_TEMPLATES, onLoadCustomInsightsTemplates);
  yield takeEvery(INSIGHTS.LOAD_HIDE_CUSTOM_INSIGHT, onLoadHideCustomInsight);
  yield takeEvery(INSIGHTS.LOAD_SHOW_CUSTOM_INSIGHT, onLoadShowCustomInsight);
}

export default function* sagas() {
  yield fork(watchInitialize);
}
