import React from 'react';
import {useTheme} from "@mui/material";

const Logo = (props) => {
  const theme = useTheme();
  const fillColor = props.fillColor ? props.fillColor : theme.palette.primary.main;
  const secondaryFillColor = theme.palette.primary.darkest;

  return (
    <img src="/static/images/logo_light.png"  alt="logo" height={props.height} width={props.width}/>
  );
}

export default Logo;
