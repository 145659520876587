import connect from "react-redux/es/connect/connect";
import {Box} from "@mui/material";
import Table from "@/components/vis/Table";
import SimpleRectangleVisSkeleton from "../../../../components/vis/SimpleRectangleVisSkeleton";

const stateToProps = ({ insights: {sections: { problemOrFeatureMentions } }}) => {
  return {
    isLoading: problemOrFeatureMentions.isLoading,
    isLoadingForALongTime: problemOrFeatureMentions.isLoadingForALongTime,
    data: problemOrFeatureMentions.data,
  };
};

const VisProductMatrix = ({ sx = {}, isLoading, isLoadingForALongTime, data, filters }) => {
  const problemOrFeatureMentionsHasData = data?.problem_or_feature_mentions?.value && data.problem_or_feature_mentions.value.length > 0;

  return (
    <Box sx={{ bgcolor: "background.paper", ...sx }}>
      {isLoading && (
        <SimpleRectangleVisSkeleton sx={{ width: "100%", height: '400px' }}
                                    animation="wave"
                                    title={isLoadingForALongTime ? "Product Matrix - working" : "Product Matrix"} />
      )}

      {!isLoading && !problemOrFeatureMentionsHasData && (
        <SimpleRectangleVisSkeleton sx={{ width: "100%", height: '400px' }}
                                    animation={false}
                                    title="Product Matrix - no data" />
      )}

      {!isLoading && problemOrFeatureMentionsHasData && (
        <Table sx={{ width: "100%", height: "100%" }}
               tableContainerMaxWidth="100%"
               tableContainerMaxHeight="400px"
               headings={[
                 { key: "issue", label: "Issue", numeric: false, disablePadding: false, percentage: false },
                 { key: "occurrence", label: "Occurrence", numeric: true, disablePadding: false, percentage: false },
                 { key: "variation", label: "Variation", numeric: false, disablePadding: false, percentage: true },
               ]}
               rows={data.problem_or_feature_mentions.value.map(({ result, current, delta, percentage }) => ({
                 issue: result,
                 occurrence: current,
                 variation: delta,
               }))}
               filters={filters}
               title="Product Matrix" />
      )}
    </Box>
  );
}

export default connect(stateToProps)(VisProductMatrix);