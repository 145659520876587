import {call, fork, put, race, select, take, takeLatest} from 'redux-saga/effects';
import Observable from 'Observable';
import {LOCATION_CHANGE} from 'connected-react-router';

import * as AUTH from './types';
import * as USER from '../../user/store/types';
import {apiGet} from 'AppUtils/api';
import {apiPost} from "../../../utils/api";
import {deleteCookie, getXsrfValueFromCookie} from "../../../utils/cookies";
import {COOKIE_NAMES} from "../../../constants";
import {uiGetSection} from "../../../store/selectors";
import {
  setLoginAuthenticated,
  setLoginEmailVerified,
  setTwoFactorSet,
  setTwoFactorValidated
} from "../../../utils/localStorage";

const resetXsrfToken = () => {
  deleteCookie(COOKIE_NAMES.XSRF_TOKEN);
};

function* initRestrictions() {
  while (true) {
    // Wait for the LOGIN_CHECK to issue set or reset
    yield race({
      set: take(AUTH.SET_LOGIN),
      reset: take(AUTH.RESET_LOGIN),
      location: take(LOCATION_CHANGE),
    });
  }
}

function* accountUpdater() {
  while (true) {
    // Wait for the LOGIN_CHECK to issue set or reset
    yield race({
      set: take(AUTH.SET_LOGIN),
      reset: take(AUTH.RESET_LOGIN),
    });
  }
}

function* onLoadLoginCheck(action) {
  const uiSection = yield select(uiGetSection);
  let statusCode;
  let info;

  // Make a request and get my user info
  yield apiGet(`/user`).catch((e) => {
    return Observable.empty();
  }).mergeMap(res => {
    const resp = res.json();
    statusCode = res.status;
    return resp;
  }).toPromise().then(function (response) {
    info = response.data;
  });

  if (statusCode === 200 && info) {
    //Valid login & email is verified
    yield call(setLoginAuthenticated, [true]);
    yield call(setLoginEmailVerified, [true]);
    yield call(setTwoFactorSet, [info.two_factor_set]);
    yield call(setTwoFactorValidated, [info.two_factor_validated]);
    yield put({
      type: AUTH.SET_LOGIN,
      payload: {
        authenticated: true,
        emailVerified: true,
        statusCode: statusCode,
      }
    });

    yield put({
      type: USER.USER_SET_INFO,
      payload: {info, statusCode},
    });
  } else if (statusCode === 403) {
    //Valid login but email is not confirmed
    yield call(setLoginAuthenticated, [true]);
    yield call(setLoginEmailVerified, [false]);
    yield put({
      type: AUTH.SET_LOGIN,
      payload: {
        authenticated: true,
        emailVerified: false,
        statusCode: statusCode,
      }
    });
  }
  else {
    yield call(setLoginAuthenticated, [false]);
    yield call(setLoginEmailVerified, [false]);
    yield put({
      type: AUTH.RESET_LOGIN,
      payload: {msg: !['index', 'login', 'register'].includes(uiSection) ? 'You\'re not authenticated' : null}
    });
  }
}

function* onLoadLoginObtainXsrfToken(action) {
  resetXsrfToken();
  let xsrfTokenRequestSuccessful = false;

  yield apiGet('/csrf-cookie', {}, {sanctum: true})
    .catch((e) => Observable.of(e))
    .toPromise()
    .then((response) => {
      xsrfTokenRequestSuccessful = (response.status === 204);
    });

  yield put({type: AUTH.SET_LOGIN_OBTAIN_XSRF_TOKEN, payload: {
    xsrfTokenRequestSuccessful: xsrfTokenRequestSuccessful,
    xsrfToken: getXsrfValueFromCookie(),
  }});

  yield put({type: AUTH.LOAD_LOGIN_CHECK});
}

function* onLoadLogin(action) {
  let loginSuccessful = false;
  let twoFactorSet = false;
  let msg;
  let errors = [];
  let statusCode = 0;
  const url = new URL(window.location);

  yield(apiPost('/login', {
    email: action.payload.email,
    password: action.payload.password,
    remember: action.payload.remember,
  })
    .catch((e) => {
      loginSuccessful = false;
      return Observable.of([]);
    })
    .mergeMap(response => {
      statusCode = response.status;

      return response.json();
    })
    .toPromise()
    .then(response => {
      if (statusCode === 200) {
        loginSuccessful = true;
        twoFactorSet = response.two_factor;
      } else {
        msg = response.message;
        errors = response.errors;
      }
    }));

  yield put({type: AUTH.SET_LOGIN, payload: {
    statusCode: statusCode,
    msg: msg,
    errors: errors,
    authenticated: loginSuccessful,
  }});

  yield call(setLoginAuthenticated, statusCode === 200);
  yield call(setTwoFactorSet, [twoFactorSet]);
  yield call(setTwoFactorValidated, [false]);
  yield call(setLoginEmailVerified, [true]);

  if( loginSuccessful && !twoFactorSet ) {
    window.location.href = '/';
  }
}

function* onLoadLogout(action) {
  let logoutSuccessful = false;
  let statusCode = 0;
  let msg;

  yield(apiPost('/logout', {})
    .catch((e) => {
      logoutSuccessful = false;
      return Observable.of([]);
    })
    .toPromise()
    .then((response) => {
      statusCode = response.status;
      if (statusCode === 204) {
        logoutSuccessful = true;
      } else {
        msg = response.message;
      }
    }));

  yield put({type: AUTH.SET_LOGOUT, payload: {
    statusCode: statusCode,
    msg: msg,
    authenticated: !logoutSuccessful,
  }});

  if( logoutSuccessful ) {
    yield call(setLoginAuthenticated, [false]);
    yield call(setTwoFactorSet, [false]);
    yield call(setTwoFactorValidated, [false]);
  }

  yield put({type: AUTH.LOAD_LOGIN_OBTAIN_XSRF_TOKEN});
}

function* onLoadCheckPasswordConfirmed(action) {
  let passwordConfirmed = false;
  let msg;
  let errors = [];
  let statusCode = 0;

  yield apiGet('/user/confirmed-password-status').catch((e) => {
    return Observable.empty();
  }).mergeMap(res => {
    const resp = res.json();
    statusCode = res.status;
    return resp;
  }).toPromise().then(response => {
    passwordConfirmed = response.confirmed;
  });

  yield put({
    type: AUTH.SET_CHECK_PASSWORD_CONFIRMED,
    payload: {
      statusCode: statusCode,
      msg: statusCode === 200 ? null : 'Could not check if password is confirmed.',
      passwordConfirmed: passwordConfirmed,
    }
  });
}

function* onLoadConfirmPassword(action) {
  let passwordConfirmed = false;
  let msg;
  let errors = [];
  let statusCode = 0;

  yield(apiPost('/user/confirm-password', {password: action.payload.password})
    .catch((e) => {
      passwordConfirmed = false;
      return Observable.of([]);
    })
    .mergeMap(response => {
      statusCode = response.status;

      return response.json();
    })
    .toPromise()
    .then(response => {
      if (statusCode === 201) {
        passwordConfirmed = true;
      } else {
        msg = response.message;
        errors = response.errors;
      }
    }));

  yield put({type: AUTH.SET_CONFIRM_PASSWORD, payload: {
    statusCode: statusCode,
    msg: msg,
    errors: errors,
    passwordConfirmed: passwordConfirmed,
  }});
}

function* onLoadInitializeTwoFactor(action) {
  let initialized = false;
  let statusCode = 0;
  let msg;

  yield(apiPost('/user/two-factor-authentication', {})
    .catch((e) => {
      initialized = false;
      return Observable.of([]);
    })
    .toPromise()
    .then((response) => {
      statusCode = response.status;
      if (statusCode === 200) {
        initialized = true;
      } else {
        msg = response.message;
      }
    }));

  yield put({type: AUTH.SET_INITIALIZE_TWO_FACTOR, payload: {
      statusCode: statusCode,
      msg: msg,
      initialized: initialized,
    }});
}

function* onLoadTwoFactorQrCode(action) {
  let msg;
  let statusCode = 0;
  let qrCode;

  yield apiGet(`/user/two-factor-qr-code`)
    .catch((e) => {
      return Observable.empty();
    })
    .mergeMap(res => {
      const resp = res.json();
      statusCode = res.status;
      return resp;
    })
    .toPromise()
    .then(response => {
      qrCode = response.svg;
    });

  yield put({type: AUTH.SET_TWO_FACTOR_QR_CODE, payload: {
    statusCode: statusCode,
    msg: msg,
    qrCode: qrCode,
  }});
}

function* onLoadConfirmTwoFactor(action) {
  let msg;
  let statusCode = 0;
  let confirmed = false;
  let errors = [];

  yield(apiPost('/user/confirmed-two-factor-authentication', {code: action.payload.code,})
    .catch((e) => {
      confirmed = false;
      return Observable.of([]);
    })
    .mergeMap(response => {
      statusCode = response.status;

      return response.json();
    })
    .toPromise()
    .then(response => {
      if (statusCode === 200) {
        confirmed = true;
      } else {
        msg = response.message;
        errors = response.errors;
      }
    }));

  yield put({type: AUTH.SET_CONFIRM_TWO_FACTOR, payload: {
    statusCode: statusCode,
    msg: msg,
    errors: errors,
    confirmed: confirmed,
  }});

  if( confirmed ) {
    setTwoFactorSet(true);
    window.location.href = '/';
  }
}

function* onLoadValidateTwoFactorChallenge(action) {
  let msg;
  let statusCode = 0;
  let challengeValidated = false;
  let errors = [];

  yield(apiPost('/two-factor-challenge', {code: action.payload.code,})
    .catch((e) => {
      challengeValidated = false;
      return Observable.of([]);
    })
    .mergeMap(response => {
      statusCode = response.status;

      if( statusCode === 204 ) {
        return Observable.of(response);
      }

      return response.json();
    })
    .toPromise()
    .then(response => {
      if (statusCode === 204) {
        challengeValidated = true;
      } else {
        msg = response.message;
        errors = response.errors;
      }
    }));

  yield put({type: AUTH.SET_VALIDATE_TWO_FACTOR_CHALLENGE, payload: {
    statusCode: statusCode,
    msg: msg,
    errors: errors,
    challengeValidated: challengeValidated,
  }});

  if( challengeValidated ) {
    window.location.href = '/';
  }
}

function* onLoadRegister(action) {
  let msg;
  let errors = [];
  let statusCode = 0;

  yield(apiPost('/register', {
    name: action.payload.name,
    email: action.payload.email,
    password: action.payload.password,
    password_confirmation: action.payload.password_confirmation,
  })
    .catch((e) => {
      return Observable.of([]);
    })
    .mergeMap(response => {
      statusCode = response.status;

      return response.json();
    })
    .toPromise()
    .then(response => {
      if( statusCode !== 201 ) {
        msg = response.message;
        errors = response.errors;
      }
    }));

  yield put({type: AUTH.SET_REGISTER, payload: {
    statusCode: statusCode,
    msg: msg,
    errors: errors,
  }});

  if( statusCode === 201 ) {
    yield call(setLoginAuthenticated, [true]);
    yield call(setTwoFactorSet, [false]);
    yield call(setTwoFactorValidated, [false]);

    window.location.href = '/';
  }
}

function* onLoadResendEmailConfirmation(action) {
  let msg;
  let statusCode = 0;
  let resent = false;

  yield apiPost('/email/verification-notification ', {})
    .catch((e) => {
      return Observable.of([]);
    })
    .mergeMap(response => {
      statusCode = response.status;

      return response.json();
    })
    .toPromise()
    .then(response => {
      if( statusCode !== 202 ) {
        msg = response.message;
      } else {
        resent = true;
      }
    });

  yield put({type: AUTH.SET_RESEND_EMAIL_CONFIRMATION, payload: {
      statusCode: statusCode,
      msg: msg,
      resent: resent,
    }});
}

function* onLoadVerifyEmail(action) {
  let emailVerified = false;
  let statusCode = 0;

  yield apiGet(action.payload.url, {}, {doNotTouchUrl: true})
    .catch((e) => Observable.of(e))
    .toPromise()
    .then((response) => {
      statusCode = response.status;
      emailVerified = statusCode === 204;
    });

  yield put({type: AUTH.SET_VERIFY_EMAIL, payload: {
    statusCode: statusCode,
    verified: emailVerified,
  }});

  window.location.href = '/';
}

function* watchInitialize() {
  yield takeLatest(AUTH.LOAD_LOGIN, onLoadLogin);
  yield takeLatest(AUTH.LOAD_LOGIN_CHECK, onLoadLoginCheck);
  yield takeLatest(AUTH.LOAD_LOGIN_OBTAIN_XSRF_TOKEN, onLoadLoginObtainXsrfToken);
  yield takeLatest(AUTH.LOAD_LOGOUT, onLoadLogout);
  yield takeLatest(AUTH.LOAD_CHECK_PASSWORD_CONFIRMED, onLoadCheckPasswordConfirmed);
  yield takeLatest(AUTH.LOAD_CONFIRM_PASSWORD, onLoadConfirmPassword);
  yield takeLatest(AUTH.LOAD_INITIALIZE_TWO_FACTOR, onLoadInitializeTwoFactor);
  yield takeLatest(AUTH.LOAD_TWO_FACTOR_QR_CODE, onLoadTwoFactorQrCode);
  yield takeLatest(AUTH.LOAD_CONFIRM_TWO_FACTOR, onLoadConfirmTwoFactor);
  yield takeLatest(AUTH.LOAD_VALIDATE_TWO_FACTOR_CHALLENGE, onLoadValidateTwoFactorChallenge);
  yield takeLatest(AUTH.LOAD_REGISTER, onLoadRegister);
  yield takeLatest(AUTH.LOAD_RESEND_EMAIL_CONFIRMATION, onLoadResendEmailConfirmation);
  yield takeLatest(AUTH.LOAD_VERIFY_EMAIL, onLoadVerifyEmail);
}

export default function* sagas() {
  yield fork(initRestrictions);
  yield fork(accountUpdater);
  yield fork(watchInitialize);
}
