import * as AUTH from './types';
import {getXsrfValueFromCookie} from "../../../utils/cookies";
import {getLoginAuthenticated, getLoginEmailVerified} from "../../../utils/localStorage";

const defaultState = {
  section: 'auth',
  login: {
    isLoading: false,
    statusCode: null,
    msg: '',
    errors: [],
    authenticated: getLoginAuthenticated() || null,
    xsrfToken: getXsrfValueFromCookie() || null,
    emailVerified: getLoginEmailVerified() || null,
  },
  passwordConfirmation: {
    isLoading: false,
    statusCode: null,
    msg: '',
    errors: [],
    passwordConfirmed: null,
  },
  twoFactor: {
    isLoading: false,
    qrCodeIsLoading: false,
    statusCode: null,
    msg: '',
    errors: [],
    qrCode: null,
    initialized: null,
    confirmed: null,
    challengeValidated: null,
  },
  registration: {
    isLoading: false,
    statusCode: null,
    msg: '',
    errors: [],
  },
  emailConfirmationResend: {
    isLoading: false,
    statusCode: null,
    msg: '',
    resent: null,
  },
  emailVerification: {
    isLoading: false,
    statusCode: null,
    verified: null,
  }
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case AUTH.LOAD_LOGIN_OBTAIN_XSRF_TOKEN: {
      return {
        ...state,
        login: {
          ...state.login,
          isLoading: true,
          xsrfToken: null,
        },
      };
    }

    case AUTH.SET_LOGIN_OBTAIN_XSRF_TOKEN: {
      return {
        ...state,
        login: {
          ...state.login,
          isLoading: false,
          xsrfToken: action.payload.xsrfToken,
        },
      };
    }

    case AUTH.LOAD_LOGIN: {
      return {
        ...state,
        login: {
          ...state.login,
          isLoading: true,
        },
      };
    }

    case AUTH.SET_LOGIN: {
      return {
        ...state,
        login: {
          ...state.login,
          isLoading: false,
          statusCode: action.payload.statusCode,
          msg: action.payload.msg,
          errors: action.payload.errors,
          authenticated: action.payload.authenticated,
          emailVerified: action.payload.emailVerified,
        }
      };
    }

    case AUTH.RESET_LOGIN: {
      return {
        ...state,
        login: {
          ...defaultState.login,
          xsrfToken: state.login.xsrfToken,
          authenticated: false,
          msg: action.payload.msg,
        },
      };
    }

    case AUTH.LOAD_LOGOUT:
      return {
        ...state,
        login: {
          ...state.login,
          isLoading: true,
        }
      }

    case AUTH.SET_LOGOUT:
      return {
        ...state,
        login: defaultState.login,
        twoFactor: defaultState.twoFactor,
      }

    case AUTH.LOAD_CHECK_PASSWORD_CONFIRMED:
      return {
        ...state,
        passwordConfirmation: {
          ...state.passwordConfirmation,
          isLoading: true,
        }
      }

    case AUTH.SET_CHECK_PASSWORD_CONFIRMED:
      return {
        ...state,
        passwordConfirmation: {
          ...state.passwordConfirmation,
          isLoading: false,
          statusCode: action.payload.statusCode,
          msg: action.payload.msg,
          passwordConfirmed: action.payload.passwordConfirmed,
        },
      };

    case AUTH.LOAD_CONFIRM_PASSWORD:
      return {
        ...state,
        passwordConfirmation: {
          ...state.passwordConfirmation,
          isLoading: true,
        }
      }

    case AUTH.SET_CONFIRM_PASSWORD:
      return {
        ...state,
        passwordConfirmation: {
          ...state.passwordConfirmation,
          isLoading: false,
          statusCode: action.payload.statusCode,
          msg: action.payload.msg,
          errors: action.payload.errors,
          passwordConfirmed: action.payload.passwordConfirmed,
        },
      };

    case AUTH.LOAD_INITIALIZE_TWO_FACTOR:
      return {
        ...state,
        twoFactor: {
          ...state.twoFactor,
          isLoading: true,
        }
      }

    case AUTH.SET_INITIALIZE_TWO_FACTOR:
      return {
        ...state,
        twoFactor: {
          ...state.twoFactor,
          isLoading: false,
          statusCode: action.payload.statusCode,
          msg: action.payload.msg,
          initialized: action.payload.initialized,
        },
      };

    case AUTH.LOAD_TWO_FACTOR_QR_CODE:
      return {
        ...state,
        twoFactor: {
          ...state.twoFactor,
          qrCodeIsLoading: true,
        },
      };

    case AUTH.SET_TWO_FACTOR_QR_CODE:
      return {
        ...state,
        twoFactor: {
          ...state.twoFactor,
          qrCodeIsLoading: false,
          statusCode: action.payload.statusCode,
          msg: action.payload.msg,
          qrCode: action.payload.qrCode,
        },
      };

    case AUTH.LOAD_CONFIRM_TWO_FACTOR:
      return {
        ...state,
        twoFactor: {
          ...state.twoFactor,
          isLoading: true,
        },
      };

    case AUTH.SET_CONFIRM_TWO_FACTOR:
      return {
        ...state,
        twoFactor: {
          ...state.twoFactor,
          isLoading: false,
          statusCode: action.payload.statusCode,
          msg: action.payload.msg,
          errors: action.payload.errors,
          confirmed: action.payload.confirmed,
        },
      };

    case AUTH.LOAD_VALIDATE_TWO_FACTOR_CHALLENGE:
      return {
        ...state,
        twoFactor: {
          ...state.twoFactor,
          isLoading: true,
        },
      }

    case AUTH.SET_VALIDATE_TWO_FACTOR_CHALLENGE:
      return {
        ...state,
        twoFactor: {
          ...state.twoFactor,
          isLoading: false,
          statusCode: action.payload.statusCode,
          msg: action.payload.msg,
          errors: action.payload.errors,
          challengeValidated: action.payload.challengeValidated,
        },
      };

    case AUTH.LOAD_REGISTER:
      return {
        ...state,
        registration: {
          ...state.registration,
          isLoading: true,
        }
      }

    case AUTH.SET_REGISTER:
      return {
        ...state,
        registration: {
          ...state.registration,
          isLoading: false,
          statusCode: action.payload.statusCode,
          msg: action.payload.msg,
          errors: action.payload.errors,
        }
      }

    case AUTH.LOAD_RESEND_EMAIL_CONFIRMATION:
      return {
        ...state,
        emailConfirmationResend: {
          ...state.emailConfirmationResend,
          resent: false,
          isLoading: true,
        }
      }

    case AUTH.SET_RESEND_EMAIL_CONFIRMATION:
      return {
        ...state,
        emailConfirmationResend: {
          ...state.emailConfirmationResend,
          isLoading: false,
          statusCode: action.payload.statusCode,
          msg: action.payload.msg,
          resent: action.payload.resent
        },
      };

    case AUTH.LOAD_VERIFY_EMAIL:
      return {
        ...state,
        emailVerification: {
          ...state.emailVerification,
          isLoading: true,
        },
      };

    case AUTH.SET_VERIFY_EMAIL:
      return {
        ...state,
        emailVerification: {
          ...state.emailVerification,
          isLoading: false,
          statusCode: action.payload.statusCode,
          verified: action.payload.verified,
        },
      };

    default:
      return state;
  }
}
