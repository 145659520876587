import connect from 'react-redux/es/connect/connect';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormHelperText,
  Grid,
  TextField,
  Typography
} from "@mui/material";
import GuestPage from "../../../components/GuestPage";
import {Link as RouterLink} from "react-router-dom";
import Logo from "../../../components/vectors/Logo";
import React, {useEffect} from "react";
import {
  passwordConfirmationErrors,
  passwordConfirmed,
  twoFactorQrCodeIsLoading,
  twoFactorInitialized,
  twoFactorQrCode, twoFactorConfirmed, twoFactorErrors, passwordConfirmationIsLoading, twoFactorIsLoading,
} from "../store/selectors";
import {
  loadCheckPasswordConfirmed,
  loadConfirmPassword,
  loadConfirmTwoFactor,
  loadInitializeTwoFactor,
  loadTwoFactorQrCode
} from "../store/actions";
import {useFormik} from "formik";
import * as Yup from "yup";

const stateToProps = state => ({
  passwordConfirmationIsLoading: passwordConfirmationIsLoading(state),
  passwordConfirmed: passwordConfirmed(state),
  passwordConfirmationErrors: passwordConfirmationErrors(state),
  twoFactorInitialized: twoFactorInitialized(state),
  twoFactorQrCodeIsLoading: twoFactorQrCodeIsLoading(state),
  twoFactorIsLoading: twoFactorIsLoading(state),
  twoFactorQrCode: twoFactorQrCode(state),
  twoFactorConfirmed: twoFactorConfirmed(state),
  twoFactorErrors: twoFactorErrors(state),
});

const actionsToProps = dispatch => ({
  loadCheckPasswordConfirmed: data => dispatch(loadCheckPasswordConfirmed(data)),
  loadConfirmPassword: data => dispatch(loadConfirmPassword(data)),
  loadInitializeTwoFactor: data => dispatch(loadInitializeTwoFactor(data)),
  loadTwoFactorQrCode: data => dispatch(loadTwoFactorQrCode(data)),
  loadConfirmTwoFactor: data => dispatch(loadConfirmTwoFactor(data)),
});

const TwoFactor = (props) => {
  useEffect(() => {
    props.loadCheckPasswordConfirmed();
  }, []);

  useEffect(() => {
    for (const field in props.passwordConfirmationErrors) {
      if (Object.prototype.hasOwnProperty.call(props.passwordConfirmationErrors, field)) {
        passwordConfirmationFormik.setFieldError(field, props.passwordConfirmationErrors[field][0]);
      }
    }
  }, [props.passwordConfirmationErrors]);

  useEffect(() => {
    for (const field in props.twoFactorErrors) {
      if (Object.prototype.hasOwnProperty.call(props.twoFactorErrors, field)) {
        twoFactorConfirmationFormik.setFieldError(field, props.twoFactorErrors[field][0]);
      }
    }
  }, [props.twoFactorErrors]);

  useEffect(() => {
    if( props.passwordConfirmed ) {
      props.loadInitializeTwoFactor();
    }
  }, [props.passwordConfirmed]);

  useEffect(() => {
    if( props.twoFactorInitialized ) {
      props.loadTwoFactorQrCode();
    }
  }, [props.twoFactorInitialized]);

  const passwordConfirmationFormik = useFormik({
    initialValues: {
      password: '',
      submit: null,
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().max(255).required('The password is obviously required'),
    }),
    onSubmit: async (values) => {
      props.loadConfirmPassword({
        password: values.password,
      });
    },
  });

  const twoFactorConfirmationFormik = useFormik({
    initialValues: {
      code: '',
      submit: null,
    },
    validationSchema: Yup.object().shape({
      code: Yup.string().max(255).required('The code is obviously required'),
    }),
    onSubmit: async (values) => {
      props.loadConfirmTwoFactor({
        code: values.code,
      });
    }
  });

  const obscureForm = (concernedFormik, label, name, isLoading) => (
    <form noValidate onSubmit={concernedFormik.handleSubmit}>
      <Grid container={true} spacing={6}>
        <Grid item md={12} xs={12}>
          <TextField error={Boolean(concernedFormik.touched[name] && concernedFormik.errors[name])}
                     fullWidth
                     helperText={concernedFormik.touched[name] && concernedFormik.errors[name]}
                     label={label}
                     margin="normal"
                     name={name}
                     onBlur={concernedFormik.handleBlur}
                     onChange={concernedFormik.handleChange}
                     type="password"
                     value={concernedFormik.values[name]}
                     variant="outlined" />

          {concernedFormik.errors.submit && (
            <Box mt={3}>
              <FormHelperText error>
                {concernedFormik.errors.submit}
              </FormHelperText>
            </Box>
          )}
        </Grid>

        <Grid item md={12} xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <Button color="primary" disabled={concernedFormik.isSubmitting} size="large" type="submit" variant="contained">
              Submit

              {isLoading && (
                <CircularProgress sx={{ ml: 2 }} size="26px" color="secondary" />
              )}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );

  return (
    <GuestPage title="Two Factor Setup">
      <Box sx={{ width: 1, height: 1, minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box sx={{ maxWidth:'sm' }}>
          <Box mb={8} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo  width={150}/>
            </RouterLink>
          </Box>

          <Card variant="outlined">
            <CardContent>
              <Box sx={{ p: 2 }}>
                <Box sx={{ mb: 3 }}>
                  <Typography variant="h2" sx={{ fontWeight: 'regular', color: 'text.primary' }}>
                    Set up your two factor authentication
                  </Typography>
                </Box>

                {props.passwordConfirmed === false && (
                  <Box flexGrow={1} mt={4}>
                    <Typography color="textSecondary">First, we'll need you to confirm your password, then we'll get you a QR code that you can scan with the authenticator app of your choice.</Typography>

                    <Box mt={2}>
                      {obscureForm(passwordConfirmationFormik, 'Password', 'password', props.passwordConfirmationIsLoading)}
                    </Box>
                  </Box>
                )}

                {props.passwordConfirmed === true && (
                  <Box flexGrow={1} mt={4}>
                    <Typography sx={{ mb: 6 }} color="textSecondary">
                      You need to scan the QR code below with your authenticator app of choice in order to set up the two factor authentication. After scanning, you'll be able to generate codes from the app that you'll use to confirm to Isara AI that it's indeed you who's logging in.
                    </Typography>

                    <Box sx={{ width: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      {props.twoFactorQrCodeIsLoading && (
                        <CircularProgress sx={{ ml: 2 }} size="26px" color="secondary" />
                      )}

                      {!props.twoFactorQrCodeIsLoading && (
                        <Box sx={{ width: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          <Box sx={{ mb: 3 }} dangerouslySetInnerHTML={{ __html: props.twoFactorQrCode }} />

                          <Box sx={{ width: 1 }} >
                            {obscureForm(twoFactorConfirmationFormik, 'Code', 'code', props.twoFactorIsLoading)}
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>
                )}
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </GuestPage>
  );
};

export default connect(stateToProps, actionsToProps)(TwoFactor);