import React, {useEffect, useState} from 'react';
import {Group} from '@visx/group';
import {Pie as VisxPie} from '@visx/shape';
import {scaleOrdinal} from '@visx/scale';
import {animated, useTransition, interpolate} from '@react-spring/web';
import {Box, Typography, Paper, IconButton, useTheme, Tooltip, ButtonGroup} from '@mui/material';
import {toHumanPercentage} from "../../utils/numbers";
import {CloudDownload, TableChart, ViewList} from "@mui/icons-material";
import {convertJSONtoCSV, downloadCSV, prepareFiltersForCSV} from "../../utils/csvDownload";
import Table from "@/components/vis/Table";
import {PieChart} from "react-feather";

const defaultMargin = {top: 20, right: 20, bottom: 20, left: 20};

export default function Pie({
                              title,
                              data,
                              width,
                              height,
                              margin = defaultMargin,
                              filters,
                              vertical = false,
                            }) {
  const theme = useTheme();
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  const [visData, setVisData] = useState([]);
  const [viewType, setViewType] = useState('pie');

  useEffect(() => {
    const top5 = data.slice(0, 5);
    const others = data.slice(5);
    const otherSum = others.reduce((acc, item) => acc + item.value, 0);

    if (otherSum > 0) {
      top5.push({ label: 'Others', value: otherSum });
    }

    setVisData(top5);
  }, [data]);

  if (width < 10) return null;

  const colors = [
    theme.palette.vis.pie.one,
    theme.palette.vis.pie.two,
    theme.palette.vis.pie.three,
    theme.palette.vis.pie.four,
    theme.palette.vis.pie.five,
    theme.palette.vis.pie.six,
  ];

  const getColor = scaleOrdinal({
    domain: visData.map((d) => d.label),
    range: Object.values(colors),
  });

  const headings = [
    { key: "label", label: "Label" },
    { key: "value", label: "Value" },
  ];

  const rows = data ? data.map(row => {
    return {
      label: row.label,
      value: toHumanPercentage(row.value)
    };
  }) : [];

  const download = () => {
    const downloadableFilters = prepareFiltersForCSV(filters);

    const headings = [
      {"key": "label", "label": "Language"},
      {"key": "value", "label": "Percentage"}
    ];

    const downloadableData = [
      headings.map(heading => heading.label),
      ...data.map(row => headings.map(heading => row[heading.key])),
      ...downloadableFilters,
      ['time', (new Date()).toDateString()]
    ];

    const csvContent = convertJSONtoCSV(downloadableData);

    if (csvContent) {
      downloadCSV(downloadableData, title);
    }
  };

  return (
    <Paper sx={{width: '100%', height: '100%', p: 4, position: 'relative', '&:hover .download-icon': { visibility: 'visible' }}}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {title && <Typography sx={{fontWeight: 'bold'}}>{title}</Typography>}

        <ButtonGroup variant="contained" aria-label="Display type button group" size="small">
          <Tooltip title="Pie view">
            <IconButton aria-label="pie-view"
                        size="small"
                        color={viewType === 'pie' ? 'primary' : 'secondary'}
                        onClick={() => setViewType('pie')}>
              <PieChart />
            </IconButton>
          </Tooltip>

          <Tooltip title="List view">
            <IconButton aria-label="table-view"
                        size="small"
                        color={viewType === 'table' ? 'primary' : 'secondary'}
                        onClick={() => setViewType('table')}>
              <ViewList />
            </IconButton>
          </Tooltip>
        </ButtonGroup>
      </Box>

      {viewType === 'pie' && (
        <Box sx={{display: 'flex', flexDirection: vertical ? 'column' : 'row', mt: 4}}>
          <Box sx={{alignSelf: 'center'}}>
            <svg width={width} height={height}>
              <Group top={centerY + margin.top} left={centerX + margin.left}>
                <VisxPie
                  data={visData}
                  pieValue={(d) => d.value}
                  outerRadius={radius}
                  cornerRadius={3}
                  padAngle={0.005}
                  pieSortValues={() => 0}
                >
                  {(pie) => (
                    <AnimatedPie
                      {...pie}
                      animate={true}
                      getKey={(arc) => arc.data.label}
                      getColor={(arc) => getColor(arc.data.label)}
                    />
                  )}
                </VisxPie>
              </Group>
            </svg>
          </Box>

          <Box sx={{display: 'flex', flexDirection: 'column', mt: 4, flex: '1 1 0%', overflow: 'hidden'}}>
            {visData.filter((d) => d.label).map((d) => (
              <Box key={d.label} sx={{display: 'flex', alignItems: 'center', mb: 2, width: 1}}>
                <Box sx={{ width: 10, height: 10, marginRight: 2, borderRadius: '50%', backgroundColor: getColor(d.label) }}/>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', flex: '1 1 0%' }}>
                  <Typography>{d.label}</Typography>
                  <Typography color="text.secondary">{toHumanPercentage(d.value)}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      )}

      {viewType === 'table' && (
        <Box sx={{ my: 4, maxHeight: '400px', overflowY: 'auto' }}>
          <Table
            sx={{ my: 4 }}
            headings={headings}
            rows={rows}
            borders={false}
            hideHeadings={true}
            hideDownload={true}
          />
        </Box>
      )}

      <Tooltip title="Download">
        <IconButton aria-label="download"
                    className="download-icon"
                    onClick={download}
                    sx={{ position: 'absolute', top: '-16px', right: '-16px', visibility: 'hidden' }}>
          <CloudDownload />
        </IconButton>
      </Tooltip>
    </Paper>
  );
}

const fromLeaveTransition = ({endAngle}) => ({
  startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  opacity: 0,
});

const enterUpdateTransition = ({startAngle, endAngle}) => ({
  startAngle,
  endAngle,
  opacity: 1,
});

function AnimatedPie({animate, arcs, path, getKey, getColor, onClickDatum}) {
  const transitions = useTransition(arcs, {
    from: animate ? fromLeaveTransition : enterUpdateTransition,
    enter: enterUpdateTransition,
    update: enterUpdateTransition,
    leave: animate ? fromLeaveTransition : enterUpdateTransition,
    keys: getKey,
  });
  return transitions((props, arc, {key}) => {
    const hasSpaceForLabel = arc.endAngle - arc.startAngle >= 0.1;

    return (
      <g key={key}>
        <animated.path
          d={interpolate(
            [props.startAngle, props.endAngle],
            (startAngle, endAngle) =>
              path({
                ...arc,
                startAngle,
                endAngle,
              })
          )}
          fill={getColor(arc)}
        />
        {hasSpaceForLabel && <animated.g style={{opacity: props.opacity}}/>}
      </g>
    );
  });
}